
$(document).ready(function () {
  $('[data-toggle="tooltip"]').tooltip();

  $(".heading-compose").click(function () {
    $(".side-two").css({
      "left": "0"
    });
  });

  $(".newMessage-back").click(function () {
    $(".side-two").css({
      "left": "-100%"
    });
  });
});
